<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">Laskut</div>

    <v-card class="mt-2">
      <v-container>
        <!-- Search by dates -->
        <search-by-dates v-show="tab === 0"></search-by-dates>

        <v-row dense>
          <v-col cols="12" sm="10" md="8" lg="6" xl="6">
            <v-text-field
              v-model="search"
              :label="getInvoiceSearchLabelByService('invoice')"
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>

        <div style="max-width: 360px">
          <v-checkbox
            v-if="tab == 0 && hideAdditionalSearchBtns"
            v-model="showPaidInvoices"
            label="Näytä maksetut laskut"
            dense
            hide-details
          ></v-checkbox>

          <!-- <v-checkbox
            v-if="isAuthorized('invoice', 'showInactiveBaseInvoices') && tab == 0"
            v-model="showInactiveBaseInvoices"
            label="Näytä korvatut toistuvaislaskut"
            dense
            class="mr-5"
            hide-details
          ></v-checkbox> -->

          <v-checkbox
            v-if="tab == 0"
            v-model="showActiveRecurrent"
            label="Näytä aktiiviset toistuvaislaskut"
            dense
            hide-details
          ></v-checkbox>
        </div>

        <v-row dense class="mt-1">
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForms()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="invoices"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :item-class="appendStyle"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template #top>
          <v-container>
            <v-tabs v-model="tab" flat color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab v-for="(item, index) in tabs" :key="'a' + index">
                <span v-if="item != 'Vaatii toimenpiteitä'">{{ item }} </span>
                <div v-else>
                  <span
                    v-if="item == 'Vaatii toimenpiteitä' && measurementsNeeded > 0"
                    class="error--text"
                  >
                    {{ measurementsNeeded }}
                  </span>
                  <span>
                    {{ item }}
                  </span>
                </div>
              </v-tab>
            </v-tabs>
          </v-container>
        </template>

        <!-- CreatedBy -->
        <template #[`item.createdBy`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="switchToAccount(item.createdBy, '/invoices')"
            ><strong>{{ item.createdBy.name }}</strong></span
          >
        </template>

        <!-- billDate -->
        <template #[`item.billDate`]="{ item }">
          <span>{{ formatDate(item.billDate) }}</span>
        </template>

        <!--  accountDate -->
        <template #[`item.accountDate`]="{ item }">
          <span>{{ formatDate(item.accountDate) }}</span>
        </template>

        <!--  dueDate -->
        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- Next send date -->
        <template #[`item.paymentTerm`]="{ item }">
          <span v-if="item.isRecurrent && item.nextDueDate">
            {{ formatDate(invoiceHelpers.calcNextRecurrentBillDate(item)) }}
          </span>
        </template>

        <!-- Ropo24 nextDueDate -->
        <template #[`item.nextDueDate`]="{ item }">
          <span v-if="item.nextDueDate" class="font-weight-bold success--text">
            {{ formatDate(item.nextDueDate) }}
          </span>
          <!-- <span v-if="tab == 2" class="error--text">Vaatii muokkausta</span> -->
        </template>

        <!-- InvoiceNumber -->
        <template #[`item.invoiceNumber`]="{ item }">
          <p
            v-if="tab == 0 && !showPaidInvoices && !showActiveRecurrent"
            :class="getInvoiceStateClass(getInvoiceState(item))"
            style="font-size: 12px"
          >
            {{ getInvoiceState(item) }}
          </p>
          <span># {{ item.invoiceNumber ? item.invoiceNumber : "-" }} </span>
        </template>

        <!-- Tenant -->
        <template #[`item.tenant`]="{ item }">
          <div v-if="invoiceTenant(item).isReceiver">
            <span
              class="primary--text"
              style="cursor: pointer"
              @click="
                switchToAccount(
                  item.createdBy,
                  `/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`
                )
              "
              >{{ invoiceTenant(item).tenant.tenantId.name }}</span
            >
          </div>

          <div v-else>
            <span>{{
              invoiceTenant(item).showUpdatedTenant ? item.updatedTenant.name : item.tenant.name
            }}</span>
            <br />
            <span
              class="primary--text"
              style="cursor: pointer"
              @click="
                switchToAccount(
                  item.createdBy,
                  `/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`
                )
              "
              >{{ invoiceTenant(item).tenant.tenantId.name }}</span
            >
          </div>
        </template>

        <!-- Apartment Address -->
        <template #[`item.contract`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="
              switchToAccount(
                item.createdBy,
                `/apartment/overview/${item.contract.apartment.id._id}`
              )
            "
            >{{ item.contract.apartment.id.address
            }}<span v-if="item.contract.apartment.id.apartmentNumber"
              >, {{ item.contract.apartment.id.apartmentNumber }}
            </span>
          </span>

          <div v-for="(room, idx) in getRentedRooms(item.contract.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!-- Products / show only on drafts!  -->
        <template #[`item.productDrafts`]="{ item }">
          <div v-html="formatProductDrafts(item)"></div>
        </template>

        <!-- Products / show on activeRecurrent  -->
        <template #[`item.products`]="{ item }">
          <div v-html="formatProducts(item)"></div>
        </template>

        <!-- Comments -->
        <template #[`item.comments`]="{ item }">
          <span class="mr-1">{{ formatCommentsInTableRow(item.comments) }}</span>
          <span
            v-if="item.comments.length > 0"
            style="cursor: pointer"
            @click="
              selected = item;
              dialogComments = true;
            "
            ><v-icon small>mdi-comment-edit-outline </v-icon></span
          >
        </template>

        <!-- SendTypes -->
        <template #[`item.sendType`]="{ item }">
          <p class="warning--text" v-if="tab == 1 && item.sendStatus == 'pending'">
            Odottaa lähetystä
          </p>

          <span v-if="(tab == 0 && !showActiveRecurrent) || tab == 1"
            ><v-icon small>{{ getSendIcon(item.sendType) }}</v-icon>
          </span>

          <span v-if="tab == 1 && item.autoSendType && item.isRecurrent"> / </span>

          <span
            v-if="
              tab == 0 && showActiveRecurrent && item.activeRentIncreaseInUse && item.nextSendType
            "
            ><v-icon small>{{ getSendIcon(item.nextSendType) }}</v-icon> >
          </span>

          <span
            v-if="
              (tab == 0 && showActiveRecurrent) ||
              (tab == 1 && item.autoSendType && item.isRecurrent) ||
              tab == 2
            "
          >
            <v-icon small>{{ getSendIcon(item.autoSendType) }}</v-icon>
          </span>
        </template>

        <!-- Total amount -->
        <template #[`item.totalAmount`]="{ item }">
          <span class="font-weight-bold success--text">{{ formatCurrency(item.totalAmount) }}</span>
        </template>

        <!-- Outgoing payments -->
        <template #[`item.outgoingPayments`]="{ item }">
          <span v-if="item.outgoingPayments.inUse" class="font-weight-bold success--text"
            >Käytössä</span
          >
          <span v-else class="font-weight-bold error--text">Ei käytössä</span>
        </template>

        <!-- Open amount -->
        <template #[`item.openAmount`]="{ item }">
          <open-amount
            :item="item"
            @openpaidamountdialog="openPaidAmountDialog(item)"
          ></open-amount>
        </template>

        <!-- Errors -->
        <template #[`item.sendingErrors`]="{ item }">
          <span
            v-if="item.sendingErrors.length > 0"
            style="cursor: pointer"
            @click="
              selected = item;
              dialogErrors = true;
            "
            ><v-icon color="error" small>mdi-comment-edit-outline</v-icon></span
          >
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="
                isAuthorized('invoice', 'read') &&
                hasServiceId(item) &&
                !showActiveRecurrent &&
                tab != 2
              "
              title="Lataa pdf"
              small
              @click="
                downloadItem = { ...item };
                downloadPdfDialog = true;
              "
              >mdi-file-pdf</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('invoice', 'read') &&
                !item.active &&
                !item.overwritten &&
                !hasServiceId(item) &&
                canDownloadPreviewInvoice(item)
              "
              class="grid-item"
              title="Esikatsele"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(true, null);
              "
              >mdi-magnify</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei laskuja</h2>
        </template>
      </v-data-table>

      <!-- Dialog comments -->
      <comments-dialog v-model="dialogComments" :invoice="selected"></comments-dialog>

      <!-- Dialog PaidAmounts -->
      <paid-amounts v-model="dialogPaidAmounts" :invoice="selected"></paid-amounts>

      <!-- Dialog Errors -->
      <errors-dialog v-model="dialogErrors" :invoice="selected"></errors-dialog>

      <!-- Dialog download pdf -->
      <download-invoice-dialog
        v-model="downloadPdfDialog"
        :invoice="downloadItem"
        @downloadpdf="downloadPdf(false, $event)"
      ></download-invoice-dialog>
    </v-card>
  </div>
</template>

<script>
import PaidAmounts from "@/components/Invoice/PaidAmountsDialog";
import ErrorsDialog from "@/components/Invoice/ErrorsDialog";
import CommentsDialog from "@/components/Invoice/CommentsDialog";
import SearchByDates from "../../../components/Invoice/SearchByDates.vue";
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import moment from "moment";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import invoiceHelpers from "@/utils/invoiceHelpers";
import globalValues from "@/configs/globalValues";
import DownloadInvoiceDialog from "@/components/Invoice/DownloadInvoiceDialog.vue";
import OpenAmount from "@/components/Invoice/TableComponents/OpenAmount";

export default {
  title: "Laskut",
  mixins: [mixins, invoiceMixins],

  components: {
    PaidAmounts,
    ErrorsDialog,
    CommentsDialog,
    SearchByDates,
    DownloadInvoiceDialog,
    OpenAmount,
  },

  data: () => ({
    loading: true,
    options: {},
    page: 1,
    downloadPdfDialog: false,
    downloadItem: {},
    downloadStatusCode: 0,
    dialogComments: false,
    dialogPaidAmounts: false,
    dialogErrors: false,
    selected: { comments: [], sendingErrors: [], paidAmounts: [] },
    search: "",
    hideAdditionalSearchBtns: true,
    allHeaders: [
      {
        text: "Tili",
        value: "createdBy",
        show: [0, 1, 2],
      },
      {
        text: "Lasku",
        value: "invoiceNumber",
        show: [0, 2],
      },
      {
        text: "Virheet",
        value: "sendingErrors",
        show: [6],
      },
      {
        text: "Laskun pvm.",
        value: "billDate",
        show: [0, 1, 2, 5],
      },
      // {
      //   text: "Kirjauspvm.",
      //   value: "accountDate",
      //   show: [0, 1, 2, 5],
      // },
      {
        text: "Eräpäivä",
        value: "dueDate",
        show: [0, 1, 2, 5],
      },
      {
        text: "Sopimus",
        value: "contract.contractNumber",
        show: [0, 1, 2],
        sortable: false,
      },
      {
        text: "Saaja",
        value: "tenant",
        show: [0, 1],
      },
      {
        text: "Kohde",
        value: "contract",
        show: [0, 1, 2],
      },
      {
        text: "Tuotteet",
        value: "productDrafts",
        show: [1, 4],
        sortable: false,
      },
      {
        text: "Tuotteet",
        value: "products",
        show: [0, 4],
        sortable: false,
      },
      {
        text: "Saatavat",
        value: "totalAmount",
        show: [0, 1, 2, 5],
      },
      {
        text: "Seuraava lähetyspäivä",
        value: "paymentTerm",
        show: [0, 1, 4],
        sortable: false,
      },
      {
        text: "Seuraava eräpäivä",
        value: "nextDueDate",
        show: [0, 1, 4],
      },
      {
        text: "Tila",
        value: "comments",
        show: [0, 5],
      },
      {
        text: "Lähtevät maksut",
        value: "outgoingPayments",
        show: [7],
        sortable: false,
      },
      {
        text: "Avoinna",
        value: "openAmount",
        show: [0, 5],
      },
      {
        text: "Toimitus",
        value: "sendType",
        show: [0, 1],
      },
      {
        text: "Toiminnot",
        value: "actions",
        show: [0, 1],
        sortable: false,
      },
    ],
    tabs: ["Laskut", "Luonnokset", "Vaatii toimenpiteitä"],
    showInactiveBaseInvoices: false,
    showPaidInvoices: false,
    showActiveRecurrent: false,
    invoiceHelpers,
    globalValues,
  }),

  computed: {
    ...mapState("invoice", ["totalLength", "invoices", "measurementsNeeded", "searchByDates"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("account", ["currentUser"]),

    tab: {
      get() {
        return this.$store.state.browsehistory.tab;
      },
      set(val) {
        this.$store.state.browsehistory.tab = val;
      },
    },

    headers() {
      return this.allHeaders.filter((el) => {
        // SHOW ACTIVE RECURRENT SELECTED
        if (this.showActiveRecurrent) {
          // Active, open invoices
          if (this.tab == 0) {
            return (
              (el.show.includes(0) && !el.show.includes(5)) ||
              (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
            );
          }

          // Drafts or pending
          if (this.tab == 1) {
            return (
              el.show.includes(1) ||
              (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
            );
          }
        }
        // NO ACTIVE RECURRENT SELECTED
        else {
          // Active, open invoices
          if (this.tab == 0) {
            return (
              (el.show.includes(0) && !el.show.includes(4)) ||
              (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
            );
          }

          // Drafts or pending
          if (this.tab == 1) {
            return (
              el.show.includes(1) ||
              (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
            );
          }
        }

        // Measurements needed
        if (this.tab == 2)
          return (el.show.includes(0) || el.show.includes(6)) && !el.show.includes(5);
      });
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;

          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    search(val) {
      if (val) {
        this.hideAdditionalSearchBtns = false;
      } else {
        this.hideAdditionalSearchBtns = true;
      }
    },

    tab() {
      this.getDataFromApi();
    },

    showInactiveBaseInvoices() {
      if (this.showInactiveBaseInvoices) {
        this.showActiveRecurrent = false;
      }
      this.getDataFromApi();
    },

    showPaidInvoices() {
      if (this.showPaidInvoices) {
        this.showActiveRecurrent = false;
      }
      this.getDataFromApi();
    },

    showActiveRecurrent() {
      if (this.showActiveRecurrent) {
        this.showPaidInvoices = false;
        this.showInactiveBaseInvoices = false;
      }
      this.getDataFromApi();
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  created() {
    this.resetSearchByDates("dueDate");
  },

  methods: {
    ...mapActions("account", ["switchAccount"]),
    ...mapActions("invoice", ["getAllInvoices", "deleteInvoice"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("invoice", ["setInvoice", "resetSearchByDates"]),
    ...mapMutations("pdfViewer", ["openViewer"]),
    ...mapGetters("account", ["getSelectedAccounts"]),

    openPaidAmountDialog(invoice) {
      this.selected = invoice;
      this.dialogPaidAmounts = true;
    },

    async getDataFromApi() {
      this.loading = true;

      if (this.search) {
        this.showPaidInvoices = false;
        this.showInactiveBaseInvoices = false;
      }

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const url = this.getInvoiceUrl();
      await this.getAllInvoices({ url, data: { accountSelector: this.getSelectedAccounts() } });

      this.loading = false;
    },

    getInvoiceUrl() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search.trim().toLowerCase();
      let additionalQuery = `&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      let url;

      // Base Invoices sent
      if (this.tab == 0) {
        const start = this.searchByDates.startDate;
        const end = this.searchByDates.endDate;
        const searchBy = this.searchByDates.searchBy;
        additionalQuery =
          additionalQuery + `&startDate=${start}&endDate=${end}&searchBy=${searchBy}`;

        if (this.showActiveRecurrent) {
          url =
            "/api/v1/invoices?refundInvoice=false&active=true&sendDate=exists&invoiceType=base&isRecurrent=true&nextDueDate=exists";
        } else {
          if (search) {
            url = "/api/v1/invoices?refundInvoice=false&sendDate=exists";
          } else if (this.showInactiveBaseInvoices) {
            url = this.showPaidInvoices
              ? "/api/v1/invoices?refundInvoice=false&overwritten=true&paid=true&sendDate=exists&invoiceType=base"
              : "/api/v1/invoices?refundInvoice=false&overwritten=true&sendDate=exists&invoiceType=base";
          } else {
            url = this.showPaidInvoices
              ? "/api/v1/invoices?refundInvoice=false&paid=true&sendDate=exists"
              : // Starting point where page loads
                "/api/v1/invoices?refundInvoice=false&paid=false&sendDate=exists&overwritten=false";
          }
        }
      }

      // Invoices not send, (paid and active are not relevant)
      else if (this.tab == 1) {
        url = this.showInactiveBaseInvoices
          ? "/api/v1/invoices?refundInvoice=false&sendDate=null&invoiceType=base&overwritten=true"
          : "/api/v1/invoices?refundInvoice=false&sendDate=null&invoiceType=base&overwritten=false";
      }

      // Needs measurements
      else if (this.tab == 2) {
        url =
          "/api/v1/invoices?refundInvoice=false&overwritten=false&invoiceType=base&measurementsNeeded=true";
      }

      return url + additionalQuery;
    },

    formatProductDrafts(item) {
      let productsString = "";

      item.products.forEach((el) => {
        productsString += `<div>${this.replaceProductTags(
          el.desc,
          item.dueDate,
          item.accountDate,
          item.language
        )} ${this.formatCurrency(el.count * el.amount * (1 + el.taxpr / 100))}</div>`;
      });

      return productsString;
    },

    formatProducts(item) {
      let fixedPeriodActive = false;

      if (item.fixedPeriodRecurrency.inUse) {
        const nextDueDate = new Date(moment(item.nextDueDate).format("YYYY-MM-DD"));
        const startDate = new Date(
          moment(item.fixedPeriodRecurrency.startDate).format("YYYY-MM-DD")
        );

        fixedPeriodActive =
          moment(nextDueDate).isSameOrAfter(moment(startDate, "month")) &&
          item.fixedPeriodRecurrency.repeatsLeft > 0
            ? true
            : false;
      }

      const PRODUCT_VAL = fixedPeriodActive
        ? "fixedPeriodProducts"
        : item.isUpdated
        ? "updatedProducts"
        : "products";

      let productsString = "";

      item[PRODUCT_VAL].forEach((el) => {
        if (item.nextDueDate) {
          productsString += `<div>${this.replaceProductTags(
            el.desc,
            item.nextDueDate,
            this.getNextAccountDate(item),
            item.language
          )} ${this.formatCurrency(el.count * (el.amount * (1 + el.taxpr / 100)))}</div>`;
        } else {
          productsString += `<div>${this.replaceProductTags(
            el.desc,
            item.dueDate,
            this.getNextAccountDate(item),
            item.language
          )} ${this.formatCurrency(el.count * (el.amount * (1 + el.taxpr / 100)))}</div>`;
        }
      });

      return productsString;
    },

    async downloadPdf(preview, statusCode) {
      try {
        const data = await invoiceHelpers.downloadInvoice(
          this.downloadItem,
          preview,
          statusCode,
          true
        );

        this.openViewer({
          data,
          name: `Lasku__${this.downloadItem.invoiceNumber || "esikatselu"}.pdf`,
        });
        this.downloadPdfDialog = false;
      } catch (err) {
        this.showPopup(err + " Kokeile vaihtaa laskun tyyppiä", "error");
      }
    },

    appendStyle(item) {
      if (item.overwritten) return "overwritten";
    },

    invoiceTenant(invoice) {
      const showUpdatedTenant =
        (invoice.updatedTenant &&
          invoice.updatedTenant.name &&
          this.tab === 0 &&
          this.showActiveRecurrent) ||
        (invoice.updatedTenant &&
          invoice.updatedTenant.name &&
          this.tab === 2 &&
          invoice.isRecurrent);
      return invoiceHelpers.getInvoiceTenant(invoice, showUpdatedTenant);
    },

    resetForms() {
      this.resetSearchByDates("dueDate");
      this.search = "";
      this.showPaidInvoices = false;
      this.showInactiveBaseInvoices = false;
      this.showActiveRecurrent = false;
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  font-size: 14px !important;
  opacity: 0.9 !important;
  background-color: rgb(78, 78, 78);
  padding: 25px;
}

.v-data-table >>> .overwritten {
  color: rgb(165, 165, 165) !important;
}

.paid-amounts {
  display: grid;
  grid-template-columns: 120px auto;
  grid-auto-rows: 18px;
}
</style>
